/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'plyr/dist/plyr.css';
// toast
@import 'ngx-toastr/toastr';

:root {
  --watermark: url('assets/images/default_watermark.png');
  --plyr-color-main: #D97706;
  --plyr-video-control-color-hover: black;
  --plyr-video-control-color: #D97706;
}

::selection {
  background-color: #D97706;
  color: #000;
}

body {
  font-family: 'Urbanist', sans-serif;
}

@layer components {
  .transition-std {
    @apply transition duration-300 ease-in-out;
  }

  .desktop-menu-text {
    @apply font-medium text-white hover:text-redCustom transition-std
  }

  .mobile-menu-text {
    @apply block font-semibold active:text-white
  }

  .home-title {
    @apply my-auto text-center md:text-left text-5xl md:text-7xl lg:text-8xl text-white underline decoration-redCustom underline-offset-4
  }

  .title-main {
    @apply mt-16 mx-auto text-4xl font-medium text-white underline decoration-redCustom underline-offset-4
  }

  .input-std {
    @apply bg-grayBgLight autofill:bg-grayBgLight border-0 text-white mt-1 focus:ring-redCustom drop-shadow-md shadow-dark focus:border-redCustom hover:bg-customDark transition-std block w-full sm:text-sm rounded-md
  }

  .input-std-dark {
    @apply bg-customDark autofill:bg-customDark placeholder:text-gray-700 border border-gray-700 text-white mt-1 focus:ring-redCustom shadow-dark focus:border-redCustom hover:bg-customDark transition-std block w-full sm:text-sm rounded-md invalid:border-red-500 invalid:border-red-500 invalid:ring-red-500
  }

  .input-std-invalid {
    @apply bg-customDark autofill:bg-customDark placeholder:text-gray-700 border border-gray-700 text-white mt-1 ring-red-600 shadow-dark border-red-600 hover:bg-customDark transition-std block w-full sm:text-sm rounded-md

  }

  .input-std-dark-nv {
    @apply bg-customDark autofill:bg-customDark placeholder:text-gray-700 border border-gray-700 text-white mt-1 focus:ring-redCustom shadow-dark focus:border-redCustom hover:bg-customDark transition-std block w-full sm:text-sm rounded-md
  }

  .label-std-left {
    @apply text-left block text-xs font-medium text-gray-300 mt-2
  }

  .label-std-left-validated {
    @apply text-left block text-xs font-medium text-gray-300 mt-2 after:content-['*'] after:ml-0.5 after:text-red-500
  }

  .invalid-label {
    @apply text-red-500 text-xs mt-2 text-left
  }

  .label-std-center {
    @apply text-center block text-sm text-gray-300 my-1
  }

  .button-std {
    @apply shadow-dark inline-flex rounded-full text-black font-semibold bg-redCustom border-0 py-2 px-8 focus:outline-none hover:bg-redCustom-dark transition duration-300 ease-in-out transform text-lg items-center
  }

  .button-small {
    @apply shadow-dark inline-flex rounded-full text-black font-semibold bg-redCustom border-0 py-2 px-8 focus:outline-none hover:bg-redCustom-dark transition duration-300 ease-in-out transform text-sm items-center
  }

  .button-medium {
    @apply shadow-dark inline-flex rounded-full text-black font-semibold bg-redCustom border-0 py-2 px-8 focus:outline-none hover:bg-redCustom-dark transition duration-300 ease-in-out transform text-base items-center
  }

  .button-discord {
    @apply shadow-dark inline-flex rounded-full text-white font-semibold bg-discordBlue border-0 py-2 px-8 focus:outline-none hover:bg-discordBlue-dark transition duration-300 ease-in-out transform text-sm items-center
  }

  .button-small-outline {
    @apply shadow-dark inline-flex rounded-full text-white font-semibold bg-transparent border border-redCustom py-2 px-4 focus:outline-none hover:border-white transition duration-300 ease-in-out transform text-sm items-center
  }

  .checkbox-blue {
    @apply inline-flex bg-customDark ring-0 focus:outline-none focus:ring-0 focus:ring-offset-0 checked:ring-0 cursor-pointer transition-std checked:ring-0 m-auto rounded-sm default:bg-customDark checked:bg-blue-700
  }

  .radio-redCustom {
    @apply inline-flex my-auto bg-customDark ring-0 text-redCustom focus:outline-none focus:ring-0 focus:ring-offset-0 checked:ring-0 cursor-pointer transition-std checked:ring-0 m-auto rounded-full default:bg-customDark checked:bg-redCustom
  }

  .radio-black {
    @apply inline-flex my-auto bg-black ring-0 text-red-500 focus:outline-none focus:ring-0 focus:ring-offset-0 checked:ring-0 cursor-pointer transition-std checked:ring-0 m-auto rounded-full default:bg-black checked:bg-black
  }

  .ammiratore-selection-button {
    @apply flex m-auto rounded-full text-white font-semibold bg-transparent border border-redCustom py-2 px-4 focus:outline-none hover:border-white transition duration-300 ease-in-out transform text-sm items-center
  }

  .ammiratore-selected-timeframe {
    @apply bg-redCustom text-black
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

//.plyr__video-wrapper::before {
//  position: absolute;
//  left: 50%;
//  top: 50%;
//  transform: translate(-50%, -50%);
//  z-index: 10;
//  content: '';
//  height: 75%;
//  width: 90%;
//  background: var(--watermark);
//  background-repeat: space;
//  background-size: 300px auto, auto !important;
//  opacity: 0.5;
//}

.toast-success {
  z-index: 1000005 !important;
  background-color: #00c853;
}

.toast-warning {
  z-index: 1000005 !important;
  background-color: #FBBF24;
}

.toast-error {
  z-index: 1000005 !important;
  background-color: #dc2626;
}

.toast-info {
  z-index: 1000005 !important;
  background-color: #1d4ed8;
}

.toast-warning .toast-message {
  color: black;
}

.toast-success .toast-message {
  color: black;
}

.toast-warning .toast-title {
  color: black;
}

.toast-success .toast-title {
  color: black;
}


.toast-success {
  background-image: url("./assets/images/check.svg");
}

.toast-warning {
  background-image: url("./assets/images/exclamation.svg");
}

.toast-error {
  background-image: url("./assets/images/x.svg");
}

.toast-container .ngx-toastr {
  border-radius: 5px 5px 5px 5px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #121212;
  border-radius: 10px;
  border-color: rgb(55, 65, 81);
}

::-webkit-scrollbar-thumb {
  background: var(--plyr-color-main);
  border-radius: 10px;
  cursor: pointer;
}

